import * as React from "react";
import tw, { styled } from "twin.macro";

import { Header, Footer, GlobalStyles } from "./";

export const Layout = (props) => {
  return (
    <LayoutWrapper>
      <GlobalStyles />
      <Header />
      <MainDiv>
        {props.children}
        <Footer />
      </MainDiv>
    </LayoutWrapper>
  );
};

const LayoutWrapper = tw.div`bg-primary`;

const MainDiv = styled.div`
  ${tw`w-full min-h-full mx-auto min-h-screen `}
  display:grid;
  grid-template-rows: minmax(max-content, auto) max-content;
  grid-template-columns: 100%;

  div.page-main {
    ${tw`mt-4 mx-auto max-w-[1300px]`}
  }
`;
