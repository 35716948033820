import { createGlobalStyle } from "styled-components";
import tw from "twin.macro";

// bc - border color
export const GlobalStyles = createGlobalStyle`

:root {
  --ru-blue: #0059AA;
  --ru-yellow: #FED438;
  font-size: 18px;
}

  .light {
    --bg-primary: #E5E5E5; 
    --bg-secondary: #FFFFFF;
    --bg-active: #0059AA;
    --text-primary: #333333;
    --text-secondary: #0059AA;
    --text-nav: #0059AA;
    --bc-normal: #BDBDBD; 
    --bc-active: #FED438;
    --bc-okay: #0059AA;
    --bc-error: #FF2E1F;
    --gray-one: rgba(51, 51, 51, 0.1);
    --gray-two: rgba(51, 51, 51, 0.12);
  }

  .dark {
    --bg-primary: #0C1744;
    --bg-secondary: #131C41;
    --bg-active: #0A3B75;
    --text-primary: #DDDDDD;
    --text-secondary: #FED438;
    --text-nav: #DDDDDD;
    --bc-normal: #DBDBDB; 
    --bc-active: #0A3B75;
    --bc-okay: #0059AA;
    --bc-error: #FF2E1F;
    --gray-one: rgba(0, 0, 0, 0.5);
    --gray-two: rgba(0, 0, 0, 0.35);
  }


  html {
    -webkit-text-size-adjust: 100%;
  }

body {
  font-family: 'Cardo', serif;
  ${tw`bg-primary text-primary transition-all duration-200`}
}

*, *:before, *:after {
  ${tw`m-0 p-0 box-border`}
}

hr {
${tw`my-4 mx-auto w-3/4 max-w-[750px] h-px border-0 bg-gradient-to-r from-transparent via-[var(--text-secondary)] opacity-90`}

};

a {
  ${tw`bg-transparent no-underline text-primary`}
}

h1, h3 {
  ${tw`tracking-wide font-bold`}
}

h1 {
  ${tw`text-3xl my-4 leading-8`}
}

h3 {
  ${tw`text-[2rem] my-3 leading-6 opacity-80 antialiased`}
}

p {
  ${tw`text-base whitespace-pre-line antialiased`}
}

ul, ol {
  ${tw`list-none`}
}

/* scroll bar */
/* width */
::-webkit-scrollbar {
  width: 10px;
  background: var(--bg-secondary);
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
  box-shadow: inset 0 0 5px grey;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--bg-active); 
  border-radius: 5px;
  border: 1px solid slategray;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4082BF; 
}

`;

export default GlobalStyles;
