import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import tw, { styled } from "twin.macro";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi";

const getControlValue = (currentPage, dist, direction, numPages) => {
  let destiney;
  let targetPage;
  let classes;

  if (direction === "left") {
    targetPage = currentPage - dist;
    destiney = targetPage <= 1 ? "" : `/${String(targetPage)}`;
    classes = `control ${targetPage >= 1 ? "" : "disabled"}`;
  } else {
    targetPage = currentPage + dist;
    destiney =
      targetPage >= numPages
        ? `/${String(numPages)}`
        : `/${String(targetPage)}`;
    classes = targetPage <= numPages ? "control" : "disabled control";
  }

  return { destiney, classes };
};

const PaginationWrapper = styled.div`
  ${tw`flex flex-nowrap my-6 py-0 px-4 w-full justify-around items-center`};
`;

const PagiItemWrapper = styled(Link)`
  ${tw`inline-block py-0 bg-secondary rounded-full h-[64px] w-[64px] relative hover:bg-active hover:text-white hover:font-bold hover:cursor-pointer text-center`}
  border: 1px solid #BDBDBD;
  line-height: 64px;

  &.active {
    ${tw`bg-active text-white font-bold`}/* border: none; */
  }

  &.disabled {
    ${tw`text-gray-200 dark:text-[#0C1744] hover:cursor-not-allowed pointer-events-none`}
  }

  &.invalid {
    ${tw`bg-transparent`}
  }

  &.control {
    ${tw`h-[56px] w-[56px]`}
  }

  .tooltip-text {
    ${tw`text-primary bg-transparent invisible absolute -top-10 -left-4 rounded-full text-xs p-2 w-20 shadow-one `}
  }

  &.control:hover .tooltip-text {
    ${tw`visible transition-all ease-in delay-500`}
  }

  svg {
    ${tw`w-4 h-4`}
    line-height: 56px;
  }
`;

const PagiItem = ({ data: { content, goto, classes } }) => {
  return (
    <PagiItemWrapper key={content} to={goto} className={classes}>
      {content}
    </PagiItemWrapper>
  );
};

const PagiControl = ({
  data: { target, symbol, currentPage, dist, direction, numPages },
}) => {
  const Symbol = symbol;
  const { destiney, classes } = getControlValue(
    currentPage,
    dist,
    direction,
    numPages
  );
  const pageText = destiney.length ? destiney.substring(1) : "1";
  return (
    <PagiItemWrapper
      key={symbol}
      to={`/${target}${destiney}`}
      className={classes}
      aria-label={`link to page ${pageText}`}
    >
      <Symbol /> <span className="tooltip-text">to page {pageText}</span>
    </PagiItemWrapper>
  );
};

const PagiSides = ({
  data: { direction, currentPage, numPages, target, pagiSidesNumber },
}) => {
  let pageNumbers;

  if (direction === "left") {
    pageNumbers = Array.from(
      new Array(pagiSidesNumber),
      (_, n) => currentPage + n - pagiSidesNumber
    );
  } else {
    pageNumbers = Array.from(
      new Array(pagiSidesNumber),
      (_, n) => currentPage + n + 1
    );
  }
  return (
    <>
      {pageNumbers.map((pageNumber) => {
        let classes = "";
        let content = String(pageNumber);
        let goto = `/${target}/${content}`;

        if (pageNumber === 1) {
          goto = `/${target}`;
        } else if (pageNumber < 1 || pageNumber > numPages) {
          goto = `/${target}/${content}`;
          content = "";
          classes = "invalid disabled";
        }
        return <PagiItem key={pageNumber} data={{ content, goto, classes }} />;
      })}
    </>
  );
};

export const Pagination = ({ target, numPages, currentPage }) => {
  const [pagiSidesNumber, setPagiSidesNumber] = useState(0);

  useEffect(() => {
    updatePagiPlaceholderNumber();
    return () =>
      window.removeEventListener("resize", updatePagiPlaceholderNumber);
  }, []);

  const updatePagiPlaceholderNumber = () => {
    if (typeof window === "undefined" || window.innerWidth < 640) {
      setPagiSidesNumber(0);
    } else if (window.innerWidth < 1024) {
      setPagiSidesNumber(2);
    } else {
      setPagiSidesNumber(4);
    }
  };

  typeof window !== "undefined" &&
    window.addEventListener("resize", updatePagiPlaceholderNumber);

  return (
    <PaginationWrapper>
      <PagiControl
        data={{
          symbol: FiChevronsLeft,
          currentPage,
          dist: 5,
          direction: "left",
          numPages,
          target,
        }}
      />
      <PagiControl
        data={{
          symbol: BsChevronLeft,
          currentPage,
          dist: 1,
          direction: "left",
          numPages,
          target,
        }}
      />
      <PagiSides
        data={{
          direction: "left",
          currentPage,
          numPages,
          target,
          pagiSidesNumber,
        }}
      />
      <PagiItem data={{ content: currentPage, goto: "#", classes: "active" }} />
      <PagiSides
        data={{
          direction: "right",
          currentPage,
          numPages,
          target,
          pagiSidesNumber,
        }}
      />
      <PagiControl
        data={{
          symbol: BsChevronRight,
          currentPage,
          dist: 1,
          direction: "right",
          numPages,
          target,
        }}
      />
      <PagiControl
        data={{
          symbol: FiChevronsRight,
          currentPage,
          dist: 5,
          direction: "right",
          numPages,
          target,
        }}
      />
    </PaginationWrapper>
  );
};
