import React, { useState } from "react";
import Slider from "react-slick";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import tw, { styled } from "twin.macro";
import { Modal } from ".";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CarouselWrapper = styled.div`
  ${tw`pt-4 pb-8 my-8 px-0 mx-0 w-full`}

  .photo-box {
    ${tw`my-8 mx-2 bg-secondary h-[268px] md:(h-[412px] border-[30px]) border-[18px] border-solid border-white dark:border-[#0f172a] hover:cursor-pointer`}
    box-shadow:0px 6px 18px 6px var(--gray-two)
  }

  .slick-dots li button:before {
    ${tw`text-white opacity-100 `}
  }

  .slick-dots li.slick-active button:before {
    ${tw`text-secondary`}
  }
`;

export const Carousel = ({ photo }) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    centerMode: true,
    touchMove: true,
    arrows: false,
  };

  const [showModal, setShowModal] = useState(false);
  const [photoData, setPhotoData] = useState(null);

  const openModal = (e, { image, alt }) => {
    const clickedDiv =
      e.currentTarget.closest("section").parentNode.parentNode.className;

    if (clickedDiv.includes("slick-center")) {
      document.body.style.overflowY = "hidden";
      setShowModal(true);
      setPhotoData({ image, alt });
    }
  };

  return (
    <>
      <CarouselWrapper>
        <Slider {...settings}>
          {photo.map((p) => {
            const image = getImage(p);
            const alt = p.description;
            return (
              <section
                tabIndex={-1}
                role="button"
                key={p.id}
                onClick={(e) => openModal(e, { image, alt })}
                onKeyUp={(e) => openModal(e, { image, alt })}
              >
                <GatsbyImage image={image} alt={alt} className="photo-box" />
              </section>
            );
          })}
        </Slider>
      </CarouselWrapper>
      {showModal ? (
        <Modal
          data={photoData}
          setShowModal={setShowModal}
          setPhotoData={setPhotoData}
        />
      ) : null}
    </>
  );
};
