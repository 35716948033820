import React from "react";
import tw, { styled } from "twin.macro";
import { ImQuotesLeft } from "react-icons/im";

const MessagePreviewWrapper = styled.section`
  ${tw`my-12 mx-auto max-w-[1300px] w-[95%] md:w-[80%] rounded-xl p-4 bg-secondary transition duration-500 ease-in-out`};
  box-shadow: 12px 12px 24px 12px var(--gray-one);

  & * {
    ${tw`mx-auto`}
  }

  h1,
  h3 {
    ${tw`text-center`}
  }

  h3 {
    ${tw`font-normal text-lg relative top-[-1rem] left-0`}
  }

  div {
    ${tw`w-[90%] md:w-3/4 relative`}
  }

  .quote-icon {
    ${tw`text-[#0059AA] relative left-[-1rem] top-0`}
  }

  p {
    ${tw`mt-4 text-center`}
  }

  &:hover {
    transform: translateY(-8px);
    box-shadow: 12px 24px 36px 24px var(--gray-two);
  }
`;

export const MessagePreview = ({ title, keyVerseQuote, keyVerseRef, date }) => {
  return (
    <MessagePreviewWrapper>
      <div>
        <h1>{title}</h1>
        <ImQuotesLeft className="quote-icon" />
        <h3>
          {keyVerseQuote.length > 150
            ? `${keyVerseQuote.slice(0, 100)}... (${keyVerseRef})`
            : `${keyVerseQuote} (${keyVerseRef})`}
        </h3>
      </div>

      <p>{date}</p>
    </MessagePreviewWrapper>
  );
};
