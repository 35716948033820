import React from "react";
import tw, { styled } from "twin.macro";

const PageHeroWrapper = styled.div`
  ${tw`relative w-full`}

  .hero-img {
    ${tw`max-h-[728px] overflow-hidden bg-gray-800/60 dark:bg-gray-500/60`}
  }

  .hero-img img {
    ${tw`w-full h-full object-fill object-bottom mix-blend-multiply`}
  }

  .group {
    ${tw`absolute top-[7vh] sm:top-[30%] w-full flex flex-col items-center justify-center px-4`}
  }

  .group-home {
    ${tw`absolute top-[7vh] xs:top-[75px] md:top-[15%] md:bottom-[10%] bg-white/30 dark:bg-black/40 left-2 right-2 bottom-2 md:left-14 lg:left-[10%] rounded-2xl px-4 md:w-[75%] max-w-[650px] md:p-5 lg:p-8 flex flex-col sm:gap-2 justify-center md:gap-4`}
    backdrop-filter: blur(5px);
  }

  .group-home h1 {
    ${tw`mt-0 text-lg mb-0 sm:(leading-7 mb-2 text-2xl) lg:(text-5xl leading-[4rem])`}
    line-height:1.25rem;
  }
  .group-home span {
    ${tw`block mt-0 text-xs md:text-base lg:text-xl`}
  }

  .group-home p {
    ${tw`sm:mt-2 text-xs md:text-base lg:(text-xl mt-4)`}
  }

  h1,
  h3,
  p {
    ${tw`text-white whitespace-pre-wrap`}
  }

  .group h1 {
    ${tw`mb-0 md:(text-[48px] mb-[8px]) lg:(text-[96px] mb-10) text-center`}
  }

  .group p {
    ${tw`sm:my-2 text-center`}
  }

  .button-group {
    ${tw`flex flex-nowrap justify-start gap-4 mt-1 md:my-4 lg:my-8`}
  }

  .button-group > a {
    ${tw`block px-2 md:w-[200px] h-6 md:h-8 rounded text-center text-sm sm:text-base leading-6 md:leading-8 transition duration-500 ease-in-out font-bold`}
  }

  .button-group > a:hover {
    transform: translateY(-8px);
  }

  #service-btn {
    ${tw`bg-white text-gray-600`}
  }
  #message-btn {
    ${tw`bg-transparent text-white`}
    border: 1px solid white;
  }
`;

export const PageHero = ({ img, title, text, search, children }) => {
  return (
    <PageHeroWrapper>
      {img}
      {children ? (
        <div className="group-home">{children}</div>
      ) : (
        <div className="group">
          <h1>{title}</h1>
          <p>{text}</p>
          {search ? search : ""}
        </div>
      )}
    </PageHeroWrapper>
  );
};
