import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

export const Seo = ({ title, description, author }) => {
  const { pathname } = useLocation();
  const { site, seoImage } = useStaticQuery(query);

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultAuthor,
  } = site.siteMetadata;

  const metaTitle = title || defaultTitle;
  const metaDescription = description || defaultDescription;
  const metaCanonical = pathname ? `${siteUrl}${pathname}` : null;
  const metaAuthor = author || defaultAuthor;
  const src = seoImage.childImageSharp.gatsbyImageData?.images?.fallback?.src;
  const metaImage = src ? `${siteUrl}${src}` : null;

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={metaTitle}
      titleTemplate={titleTemplate}
      link={metaCanonical ? [{ rel: "canonical", href: metaCanonical }] : []}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: "og:url",
          content: `${siteUrl}${pathname}`,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: "og:site_name",
          content: `University Bible Fellowship at Ryerson University`,
        },
        {
          name: "og:image",
          content: metaImage,
        },
        {
          name: "og:image:alt",
          content: "Ryerson Student Learning Center",
        },
        {
          name: "og:image:width",
          content: 1200,
        },
        {
          name: "og:image:height",
          content: 800,
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: "twitter:image",
          content: metaImage,
        },
        {
          name: "twitter:image:alt",
          content: "Ryerson Student Learning Center",
        },
      ]}
    />
  );
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultAuthor: author
      }
    }
    seoImage: file(name: { glob: "scott-webb-Ur-jCHaQ2AE-unsplash" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, height: 800, quality: 75, width: 1200)
      }
    }
  }
`;
