import React from "react";
import tw, { styled } from "twin.macro";
import { BsSearch } from "react-icons/bs";

export const SearchBar = ({ searchQuery, setSearchQuery }) => (
  <SearchBarWrapper action="/message" method="get" autoComplete="off">
    <input
      value={searchQuery}
      onInput={(e) => setSearchQuery(e.target.value)}
      type="text"
      placeholder="Search"
      name="s"
      id="search-message"
      aria-label="search-message"
    />
    <button type="submit" disabled aria-label="search icon">
      <BsSearch />
    </button>
  </SearchBarWrapper>
);

const SearchBarWrapper = styled.form`
  ${tw`border-none relative max-w-[480px] w-3/4`}

  input {
    ${tw`w-full mx-auto border-0 pl-10 py-1 my-2 focus:outline-none bg-primary rounded-xl text-primary`}
  }

  button {
    ${tw`border-none outline-none bg-transparent text-gray-400 absolute left-3 top-[13.5px] w-[18px] h-[18px]`}
  }

  button svg {
    ${tw`w-full h-full text-primary`}
  }
`;
