import React from "react";
import tw, { styled } from "twin.macro";
import { ThemeContext } from "./";
import { FiSun, FiMoon } from "react-icons/fi";

export const ThemeToggle = () => {
  const { theme, setTheme } = React.useContext(ThemeContext);
  const isDark = () => theme === "dark";

  return (
    <ThemeToggleWrapper
      onClick={() => setTheme(isDark() ? "light" : "dark")}
      aria-label="theme toggle"
    >
      {isDark() ? (
        <FiSun className="toggle-icon" />
      ) : (
        <FiMoon className="toggle-icon" />
      )}
    </ThemeToggleWrapper>
  );
};

const ThemeToggleWrapper = styled.button`
  ${tw`border-none cursor-pointer bg-secondary rounded-full h-[42px] w-[42px] p-[9px] mx-auto`}

  .toggle-icon {
    ${tw`text-secondary w-[24px] h-[24px] m-auto`}
  }
`;
