import React, { useState } from "react";
import tw, { styled } from "twin.macro";
import { VscTriangleUp } from "react-icons/vsc";

const AccordionWrapper = styled.div`
  ${tw`mx-4 my-[40px] bg-secondary rounded-2xl overflow-hidden text-primary`}
  box-shadow: 12px 12px 24px 12px var(--gray-one);

  button {
    ${tw`relative bg-secondary w-full h-[64px] border-none cursor-pointer p-2`}
  }

  button > div {
    ${tw`mx-4 grid grid-cols-[5.5rem auto] justify-start items-center h-full`}
  }

  &:hover {
    ${tw`shadow-two`}
  }

  .panel {
    ${tw`max-h-0 rounded-b-2xl`}
    transition: all 0.3s ease;
  }

  .panel.active {
    ${tw`max-h-[176px] mb-4`}
  }

  button p {
    ${tw`text-sm px-2 text-primary`}
    border-right: 2px solid var(--ru-blue);
  }

  h3 {
    ${tw`text-base h-full pl-2 py-2 mr-8 text-primary overflow-y-auto text-left leading-[28px]`}
  }

  span {
    ${tw`absolute rounded-full w-6 h-6 bg-active right-5 flex place-items-center duration-500 ease-in-out`}
    transform: rotate(180deg);
  }

  span.rotated {
    transform: rotate(0);
  }

  svg {
    ${tw`text-white w-full`}
  }

  p.content {
    ${tw`w-full pl-28 text-base whitespace-pre-line`}
  }
`;

export const Accordion = ({ data: { content, title, createdAt } }) => {
  const [isActive, toggleActive] = useState(false);

  const handleClickAccordion = () => {
    toggleActive(!isActive);
  };

  return (
    <AccordionWrapper>
      <button
        onClick={handleClickAccordion}
        className={`${isActive ? "active" : "inactive"}`}
        aria-label="open or close post"
      >
        <div>
          <p>{createdAt}</p>
          <h3>{title}</h3>
          <span className={`${isActive ? "rotated" : ""}`}>
            <VscTriangleUp />
          </span>
        </div>
      </button>
      <div className={`panel ${isActive ? "active" : ""}`}>
        <p className="content">{content}</p>
      </div>
    </AccordionWrapper>
  );
};
