import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";

import { BsInfoCircle, BsCalendar4Event } from "react-icons/bs";
import { BiBible } from "react-icons/bi";
import Bread from "../../images/Bread.svg";
import Envelope from "../../images/Envelope.svg";
import Note from "../../images/Note.svg";

import { ThemeToggle } from "../../components";
import {
  HeaderWrapper,
  NavMenu,
  NavLogo,
  Bars,
  Cross,
  Offcanvas,
} from "./styled";

import Logo from "../../images/logo.svg";

export const menuItems = [
  {
    name: "About",
    path: "/about",
    icon: BsInfoCircle,
  },
  {
    name: "Message",
    path: "/message",
    icon: BiBible,
  },
  {
    name: "Event",
    path: "/event",
    icon: BsCalendar4Event,
  },
  {
    name: "Blog",
    path: "/blog",
    icon: Note,
  },
  {
    name: "Daily Bread",
    path: "/dailybread",
    icon: Bread,
  },
  {
    name: "Contact",
    path: "/contact",
    icon: Envelope,
  },
];

export const LogoWhite = () => (
  <NavLogo className="white">
    <Logo />
  </NavLogo>
);
export const LogoBlue = () => (
  <NavLogo className="blue">
    <Logo />
  </NavLogo>
);

export const Header = () => {
  const [opaque, toggleOpaque] = useState(false);
  const [isMobile, toggleMobile] = useState(true);
  const [showMenu, setShowMenu] = useState(false);
  const canvasRef = useRef();

  const handleShow = () => {
    document.body.style.overflowY = "hidden";
    setShowMenu(true);
  };
  const handleClose = () => {
    document.body.style.overflowY = "auto";
    setShowMenu(false);
  };

  const handleClickBackground = (e) => {
    if (canvasRef.current === e.target) {
      handleClose();
    }
  };

  useEffect(() => {
    const isBrowser = typeof window !== "undefined";

    const isMobileView = () => {
      if (isBrowser && window.innerWidth < 1024) {
        toggleMobile(true);
      } else {
        toggleMobile(false);
        setShowMenu(false);
      }
    };

    const changeHeaderOpacity = () => {
      if (isBrowser && window.scrollY <= 10) {
        toggleOpaque(false);
      } else {
        toggleOpaque(true);
      }
    };
    window.addEventListener("resize", isMobileView);
    window.addEventListener("scroll", changeHeaderOpacity);
    isMobileView();
    return () => {
      window.removeEventListener("resize", isMobileView);
      window.removeEventListener("scroll", changeHeaderOpacity);
      handleClose(false);
    };
  }, []);

  const renderMenu = () =>
    isMobile ? (
      <NavMenu className="mobile">
        {menuItems.map(({ name, path, icon }, index) => {
          const Icon = icon;
          return (
            <Link
              key={index}
              to={path}
              activeClassName="active"
              onClick={() => {
                handleClose(false);
              }}
              className="mobile"
            >
              <div className="menu-item">
                <Icon fill="var(--text-nav)" />
                <span>{name}</span>
              </div>
            </Link>
          );
        })}
        <ThemeToggle />
      </NavMenu>
    ) : (
      <NavMenu className="non-mobile">
        {menuItems.map(({ name, path }, index) => (
          <Link
            key={index}
            to={path}
            activeClassName="active"
            onClick={() => {
              handleClose(false);
            }}
            className="non-mobile"
          >
            {name}
          </Link>
        ))}
        <ThemeToggle />
      </NavMenu>
    );

  return (
    <HeaderWrapper className={opaque ? "opaque" : "transparent"}>
      <div className="container">
        <NavLogo to="/" aria-label="Ryerson UBF logo">
          <Logo
            width="220px"
            height="40px"
            alt="Ryerson UBF logo"
            fill={opaque ? "var(--text-nav)" : "white"}
          />
        </NavLogo>

        {isMobile ? (
          <Bars onClick={handleShow} className={opaque ? "opaque" : ""} />
        ) : (
          renderMenu()
        )}
      </div>

      {showMenu ? (
        <Offcanvas
          ref={canvasRef}
          showMenu={showMenu}
          onClick={handleClickBackground}
        >
          <div className="main">
            <div className="offcanvas-header">
              <Link to="/">
                <Logo
                  width="220px"
                  height="40px"
                  alt="RUBF logo"
                  fill="var(--text-nav)"
                  onClick={() => {
                    handleClose(false);
                  }}
                />
              </Link>
              <Cross onClick={handleClose} />
            </div>
            <div className="offcanvas-menu">{renderMenu()}</div>
          </div>
        </Offcanvas>
      ) : null}
    </HeaderWrapper>
  );
};
