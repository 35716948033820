import tw, { styled } from "twin.macro";
import { Link } from "gatsby";
import { FaTimes, FaBars } from "react-icons/fa";

export const HeaderWrapper = styled.header`
  ${tw`fixed z-30 top-0 items-center h-[60px] w-full px-4`};

  .container {
    ${tw`flex h-full mx-auto justify-between items-center max-w-[1300px]`}
  }

  &.transparent {
    ${tw`bg-transparent`}
  }

  &.transparent a.non-mobile {
    ${tw`text-white`}
  }

  &.opaque {
    ${tw`bg-secondary shadow-four`}
  }
  &.opaque a.non-mobile {
    ${tw`text-nav hover:text-ryellow`}
  }
`;

export const Bars = styled(FaBars)`
  ${tw`text-white text-3xl cursor-pointer z-30`}

  &.opaque {
    ${tw`text-nav dark:text-primary`}
  }
`;
export const Cross = styled(FaTimes)`
  ${tw`text-nav text-3xl cursor-pointer z-50`}
`;

export const NavMenu = styled.div`
  ${tw`transition duration-200 h-full`};

  &.non-mobile {
    ${tw`flex space-x-4 items-center`}
  }

  &.mobile {
    ${tw`flex flex-col justify-between`}
  }

  a {
    ${tw`no-underline transition-colors ease-in-out `}
  }

  a.active {
    ${tw`!text-ryellow`}
  }

  a.non-mobile {
    ${tw`text-lg font-bold`};
  }

  a.mobile {
    ${tw`p-0 text-base`};
  }

  div.menu-item {
    ${tw`flex justify-start items-center pl-4 py-3 my-1 rounded-lg text-nav hover:(bg-gray-200/30 shadow-md) space-x-3 font-bold`};
  }

  div.menu-item svg {
    ${tw`w-4 h-4`}
  }

  .active .menu-item {
    ${tw`bg-gray-200/30 shadow-md`}
  }
`;

export const NavLogo = styled(Link)`
  ${tw`p-0`}
`;

export const Offcanvas = styled.div`
  ${tw`fixed z-30 top-0 right-0 bg-black/50 overflow-x-hidden transition-all duration-300 w-0 h-screen`}
  backdrop-filter: blur(3px);

  ${({ showMenu }) => showMenu && tw`w-full`}

  .main {
    ${tw`absolute top-0 right-0 bg-secondary w-3/4 rounded-bl-2xl`}
  }

  .offcanvas-header {
    ${tw`flex justify-between my-4 mx-2 xs:mx-8 text-nav`}
  }

  .offcanvas-menu {
    ${tw`flex flex-col content-start p-8 pt-0`}
  }
`;
