exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-dailybread-jsx": () => import("./../../../src/pages/dailybread.jsx" /* webpackChunkName: "component---src-pages-dailybread-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-template-blog-template-jsx": () => import("./../../../src/template/blog-template.jsx" /* webpackChunkName: "component---src-template-blog-template-jsx" */),
  "component---src-template-event-list-template-jsx": () => import("./../../../src/template/event-list-template.jsx" /* webpackChunkName: "component---src-template-event-list-template-jsx" */),
  "component---src-template-event-template-jsx": () => import("./../../../src/template/event-template.jsx" /* webpackChunkName: "component---src-template-event-template-jsx" */),
  "component---src-template-message-list-template-jsx": () => import("./../../../src/template/message-list-template.jsx" /* webpackChunkName: "component---src-template-message-list-template-jsx" */),
  "component---src-template-message-template-jsx": () => import("./../../../src/template/message-template.jsx" /* webpackChunkName: "component---src-template-message-template-jsx" */)
}

