import React from "react";
import tw, { styled } from "twin.macro";
import { Link } from "gatsby";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import { menuItems } from ".";
import Logo from "../images/logo.svg";

export const Footer = () => {
  return (
    <FooterWrapper>
      <div className="top-div">
        <div className="left">
          <Link to="/" aria-label="Ryerson UBF logo">
            <Logo width="220px" height="40px" alt="RUBF logo" fill="white" />
          </Link>
          <p>
            University Bible Fellowship (UBF) is an international evangelical
            church (non-denominational) and network of house churches dedicated
            to Christ and his kingdom.
          </p>
        </div>
        <div className="right">
          {menuItems.map(({ name, path }, index) => (
            <Link key={index} to={path}>
              {name}
            </Link>
          ))}
        </div>
      </div>
      <div className="bottom-div">
        <p>
          Copyright © {new Date().getFullYear()} Young Disciple Christian
          Fellowship - UBF at Ryerson
        </p>
        <div>
          <a href="https://www.facebook.com/ubfryerson" aria-label="Facebook">
            <BsFacebook />
          </a>
          <a
            href="https://www.instagram.com/charles.kim.young.disciple/"
            aria-label="Instagram"
          >
            <BsInstagram />
          </a>
        </div>
      </div>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.div`
  ${tw`bg-active text-white`}

  p {
    ${tw`whitespace-pre-line text-sm`}
  }

  a {
    ${tw`text-white hover:text-ryellow`}
  }

  .top-div {
    ${tw`flex flex-row flex-nowrap py-6 px-4 md:px-16 justify-between`}
  }

  .left {
    ${tw`w-3/5 max-w-[550px]`}
  }

  .right {
    ${tw`grid grid-cols-2 lg:grid-cols-6 gap-2 md:gap-4 justify-start items-center pl-8`}
  }

  .right > a {
    ${tw`text-left text-sm md:text-base lg:text-center`}
  }
  .bottom-div {
    ${tw`flex flex-row justify-between items-center py-2 px-4 md:px-16 bg-[#4082BF] dark:bg-[#0059AA]`}
  }

  .bottom-div > p {
    ${tw`text-xs max-w-[550px] w-3/5`}
  }

  .bottom-div > div {
    ${tw`flex flex-row justify-around items-center min-w-[130px]`}
  }

  .bottom-div a {
    ${tw`block w-[24px] h-[24px]`}
  }

  .bottom-div svg {
    ${tw`w-full h-full`}
  }
`;
