import React from "react";
import { Link } from "gatsby";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import tw, { styled } from "twin.macro";

const PrevNextPageWrapper = styled.div`
  ${tw`w-3/4 py-4 flex flex-row flex-nowrap justify-between mx-auto`}

  a {
    ${tw`text-primary no-underline hover:text-white transition-colors duration-500`}
  }
`;

const PagiItemWrapper = styled(Link)`
  ${tw`inline-block p-[23px] bg-secondary rounded-full h-[64px] w-[64px] relative hover:bg-active hover:text-white hover:font-bold hover:cursor-pointer text-center`}
  border: 1px solid #BDBDBD;

  &.disabled {
    ${tw`text-gray-200 dark:text-[#0C1744] hover:cursor-not-allowed pointer-events-none`}
  }

  svg {
    ${tw`w-4 h-4`}
  }
`;

const ControlDivWrapper = styled.div`
  ${tw`m-0 p-0 flex flex-row items-center gap-2`}

  &.reverse {
    ${tw`flex-row-reverse`}
  }

  span.right-aligned {
    ${tw`text-right`}
  }
`;

const ControlDiv = ({ data: { goto, symbol, text, disableLink, target } }) => {
  const Symbol = symbol;

  return (
    <ControlDivWrapper className={text === "Previous" ? "reverse" : ""}>
      <span className={text === "Previous" ? "" : "right-aligned"}>
        {text}
        <br />
        {target.charAt(0).toUpperCase() + target.slice(1)}
      </span>
      <PagiItemWrapper
        key={symbol}
        to={goto}
        className={disableLink ? "disabled" : ""}
      >
        <Symbol />
      </PagiItemWrapper>
    </ControlDivWrapper>
  );
};

export const PrevNextPage = ({ target, prevPage, nextPage }) => {
  return (
    <PrevNextPageWrapper>
      <ControlDiv
        data={{
          goto: `/${target}/${prevPage}`,
          symbol: BsChevronLeft,
          text: "Previous",
          disableLink: !prevPage,
          target,
        }}
      />

      <ControlDiv
        data={{
          goto: `/${target}/${nextPage}`,
          symbol: BsChevronRight,
          text: "Next",
          disableLink: !nextPage,
          target,
        }}
      />
    </PrevNextPageWrapper>
  );
};
