import React, { useRef } from "react";
import tw, { styled } from "twin.macro";
import { GatsbyImage } from "gatsby-plugin-image";
import { Cross } from "./Header/styled";

const ModalWrapper = styled.div`
  ${tw`fixed flex flex-col justify-center top-0 right-0 w-screen h-screen p-4 md:p-8 bg-black/90 z-40`}
  backdrop-filter: blur(5px);

  & > div {
    ${tw`m-auto w-[80vw] max-w-[75%] max-h-[80%] flex flex-col justify-center `}
  }

  & > div.portrait {
    ${tw`w-[50vw] max-w-[400px] overflow-y-auto`}
  }

  button {
    ${tw`bg-transparent border-none absolute z-30 top-[5%] right-[5%]`}
  }

  .description {
    ${tw`absolute bottom-8 left-0 w-full`}
  }

  p {
    ${tw`mx-auto w-[80vw] max-w-[75%] text-center whitespace-pre-line text-white text-base bg-black p-4`}
  }
`;

export const Modal = ({ data: { image, alt }, setShowModal, setPhotoData }) => {
  const modalRef = useRef();

  const handleClickClose = () => {
    document.body.style.overflowY = "auto";
    setShowModal(false);
    setPhotoData(null);
  };

  const handleClickBackground = (e) => {
    if (modalRef.current === e.target) {
      handleClickClose();
    }
  };

  const isPortrait = image.width / image.height < 1;

  return (
    <ModalWrapper ref={modalRef} onClick={handleClickBackground}>
      <div className={isPortrait ? "portrait" : ""}>
        <button onClick={handleClickClose}>
          <Cross />
        </button>
        <div id="image-div">
          <GatsbyImage image={image} alt={alt} />
        </div>
        <div className="description">
          <p>{alt}</p>
        </div>
      </div>
    </ModalWrapper>
  );
};
