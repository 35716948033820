import React from "react";
import tw, { styled } from "twin.macro";

const VideoPlayerWrapper = styled.div`
  ${tw`w-[311px] h-[174px] md:w-[505px] md:h-[283px] 2xl:w-[800px] 2xl:h-[450px] mx-auto my-4 md:my-8`}
  box-shadow: 12px 12px 24px 12px var(--gray-one);
`;

export const VideoPlayer = ({ videoId }) => {
  return (
    <VideoPlayerWrapper>
      <iframe
        src={`https://www.youtube.com/embed/${videoId}`}
        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
        loading="lazy"
        width="100%"
        height="100%"
        title="YouTube video player"
      />
    </VideoPlayerWrapper>
  );
};
