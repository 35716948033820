import React from "react";
import tw, { styled } from "twin.macro";

const PageBannerWrapper = styled.div`
  ${tw`relative w-full`}

  .hero-img {
    ${tw`max-h-[320px] overflow-hidden bg-gray-800/60 dark:bg-gray-500/60`}
  }

  .hero-img img {
    ${tw`w-full h-full object-fill object-bottom mix-blend-multiply`}
  }

  .group {
    ${tw`absolute top-[7vh] sm:top-24 w-full flex flex-col items-center justify-center`}
  }

  .group h1,
  .group p {
    ${tw`my-2 md:my-2 text-white text-center`}
  }
`;

export const PageBanner = ({ img, title, createdAt }) => {
  return (
    <PageBannerWrapper>
      {img}
      <div className="group">
        <h1>{title}</h1>
        <p>{createdAt}</p>
      </div>
    </PageBannerWrapper>
  );
};
